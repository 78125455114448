import { Box } from '@mui/material'
import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-bootstrap'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import BsTable from '../../Comp/BsTable'
import { DeleteApi, GetApi } from '../../Api/BaseHandle'
import Swal from 'sweetalert2'
import DelButton from '../../Comp/DelButton'
import Pagination from '../../Comp/Pagination'

const PaymentList = () => {
  let [open, setopen] = useState(false)
  let [data, setdata] = useState([])
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [ItemsPerPage, setItemsPerPage] = useState();
  const [ItemsperpageSearching, setItemsperpageSearching] = useState();
  const [Filtertabs, setFiltertabs] = useState();


  const handleDelete = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        DeleteApi("/admin/save/payment/", e._id).then((res) => {
          let filData = data.filter((x) => x._id !== e._id)
          setdata(filData)
        }).catch((e) => console.log(e))
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }
    });



  }
  const GetData = (e) => {
    GetApi("/admin/save/payment/get").then((res) => {
      setdata(res.data.data)
    }).catch((e) => console.log(e))
  }
  useEffect(() => {
    GetData()
  }, [])




  const paginate = (pageNumber) => {
    setcurrentpage(pageNumber);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setcurrentpage(1);
    // const filteredData = data.filter(item =>
    //   item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   item.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const searchTermLowerCase = e.target.value.toLowerCase();
    const filteredData = data.filter(item =>
      item.userName.toLowerCase() == searchTermLowerCase || item.userEmail.toLowerCase() == searchTermLowerCase
    );
    setFiltertabs(filteredData)
    const indexoflastpost = currentpage * postperpge;
    const indexofFirstpost = (currentpage - 1) * postperpge;
    const currentpost = (Array.isArray(filteredData) && filteredData?.length > 0) && filteredData?.slice(indexofFirstpost, Math.min(indexoflastpost, filteredData?.length));
    setItemsperpageSearching(currentpost)
  };

  useEffect(() => {
    if (Filtertabs?.length > 0) {
      const indexoflastpost = currentpage * postperpge;
      const indexofFirstpost = (currentpage - 1) * postperpge;
      const currentpost = (Array.isArray(Filtertabs) && Filtertabs?.length > 0) && Filtertabs?.slice(indexofFirstpost, Math.min(indexoflastpost, Filtertabs?.length));
      setItemsperpageSearching(currentpost)
    }
  }, [currentpage]);



  useEffect(() => {
    if (data.length > 0) {
      const indexoflastpost = currentpage * postperpge;
      const indexofFirstpost = (currentpage - 1) * postperpge;
      const currentpost = (Array.isArray(data) && data.length > 0) && data?.slice(indexofFirstpost, Math.min(indexoflastpost, data.length));
      setItemsPerPage(currentpost)
    }
  }, [data, currentpage]);


  return (
    <div>
      <MainHeader />
      <Header screenTitle="Payment List" />
      <Box>
        <Box className="px-5 " >
          <ToastContainer />
          <div>
            <input
              className='form-control w-25'
              type="text"
              placeholder="Search by Name, Email"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </Box>
        {open ?
          <Box sx={{ height: "55%" }} className="d-flex justify-content-center ">
            {/* <img className='w-25' src={Img} alt="Centered Image" /> */}
            <Box>
              <Typography variant='h6' className='text-dark pt-1 fs-4 px-2'>
                Data Not Found...
              </Typography>
              <Typography variant='p' className='text-dark pt-1 fs-6 px-2'>
                You have to Create First
              </Typography >
            </Box>
          </Box>
          :
          <BsTable data={searchTerm?.trim() != "" ? ItemsperpageSearching : ItemsPerPage} cols={[
            {
              name: "ID",
              key: "paymentId"
            },
            {
              name: "Name",
              key: "userName"
            },
            {
              name: "Email",
              key: "userEmail"
            },
            {
              name: "Plan Name",
              key: "planName"
            },
            {
              name: "Amount",
              key: "paymentAmount"
            },
            {
              name: "Date of Package",
              key: "paymentPurchaseDate"
            },
            {
              name: "Action",
              displayFeild: (e) => (
                <>
                  {/* <Button type="primary" danger onClick={() => handleDelete(e)}>Delete</Button> */}
                  <DelButton click={() => handleDelete(e)} />
                </>
              )
            },

          ]} />
        }
      </Box>
      <Pagination perpge={postperpge} total={searchTerm?.trim() != "" ? Filtertabs?.length : data?.length} paginate={paginate} />
      <BsTable />
    </div>
  )
}

export default PaymentList
