function Pagination({ perpge, total, paginate, currentpage }) {

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / perpge); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="container  pagination-container">
      <ul className='pagination mb-3'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>

            <a onClick={() => paginate(number)} className={`page-link ${currentpage === number ? 'active' : ''}`}>

              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
