
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState, } from 'react'
import BsInpFull from '../Comp/BsInpFull'
import BsButton from '../Comp/BsButton'
import BsNoti from '../Comp/BsNoti'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { PostApi } from '../Api/BaseHandle'

const Login = () => {
  // const [loading,setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const [model, setModel] = useState({})
  const navig = useNavigate()

  const loginButton = () => {
    if (!model.email) {
      toast.error('Email Requierd', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (!model.password) {
      toast.error('Password Requierd', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    setOpen(true)
    PostApi("/user/login", model)
      .then((succ) => {
        setOpen(false)
        const token = succ.data.data.token;
        if (succ.data.data.user.isAdmin == "true") {
          navig("dashboard")
      localStorage.setItem("token", token);

        } else {
          setOpen(false)
          toast.error("Not Admin ", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        setOpen(false)
        toast.error("Unathorized ", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })

  }

  return (
    <div  >
      <Box sx={{ width: { md: '360px', sm: '325px', xs: '100%', margin: "135px auto" } }} className=' shadow  '>
        <Box >
          {/* {open ?  
              <BsNoti  />
            : null} */}
          <ToastContainer />
          <Typography className='text-center py-2' variant='h5'>Quiz App </Typography>
          <Box className='px-3'>

            <Box className=' my-3  '>
              <div className='mx-1'>
                <BsInpFull label='Email' className='w-100' onChange={(e) => setModel({ ...model, email: e.target.value })} />
              </div>
            </Box>

            <Box className=' my-3  '>
              <div className='mx-1'>
                <BsInpFull label='Password' type="password" className='w-100' onChange={(e) => setModel({ ...model, password: e.target.value })} />
              </div>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }} className='py-3'>
              <BsButton isButtonDisabled={open} text="Submit" onClick={loginButton} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Login