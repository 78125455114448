import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../Dashboard/Login'
import MainDashboard from '../Dashboard/MainDashboard'
import ProtectedRoute from './ProtectedRoute'
import LoginProtected from './LoginProtected'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LoginProtected Component={Login} />} />
        <Route path='dashboard/*' element={<ProtectedRoute Component={MainDashboard} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter