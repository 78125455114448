import React, { useEffect, useState } from 'react'
import MainHeader from '../../Comp/MainHeader';
import Header from '../../Comp/Header';
import { Box, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { Select, Typography } from 'antd';
import BsButton from '../../Comp/BsButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { PutApi } from '../../Api/BaseHandle';
import axios from 'axios';
import DelButton from '../../Comp/DelButton';

const EditQuestion = () => {
    let [model, setModel] = useState({
        question: '',
        correctAnswer: '',
        explantion: '',
        answer: ['', '', '', '', '', ''],
    });
    
        // let [model, setModel] = useState({ question: '', correctAnswer: '',answer:["false","true"] });
    const [check, setcheck] = useState([]);
    const [correctAnswer, setcorrectAnswer] = useState([]);
    const [disabled, setdisabled] = useState(false);
    const [isAnyOptionEmpty, setIsAnyOptionEmpty] = useState(false);
    const [SelectRender, setSelectRender] = useState(true);
    const notify = (message) => toast.error(message);
    let location = useLocation()
    let navigate = useNavigate()



    const correctChange = (e) => {
        setcorrectAnswer(e);
        setModel({ ...model, correctAnswer: e })
    };


    useEffect(() => {
        setModel(location.state.data)
        if (location?.state?.data?.Type == "boolean") {
            setSelectRender(false)
        }
        setcorrectAnswer(location.state.data.correctAnswer[0])
        setcheck(location.state.data.answer)
    }, [])

    useEffect(() => {

        const hasEmptyOption = model?.answer?.some((option, i) => {
            return option === "" ? check[i] === "" : false;
        });

        // Check if the length is less than 3 and set isAnyOptionEmpty accordingly
        if (model?.answer?.length < 3) {
            setIsAnyOptionEmpty(true);
        } else {
            setIsAnyOptionEmpty(hasEmptyOption);
        }

    }, [model?.answer, check]);


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!model?.question.trim()) {
            notify('Question is required');
            return;
        }


        if (!model?.explantion.trim()) {
            notify('Explanation is required');
            return;
        }

        const transformedModel = {
            question: model?.question || '',
            correctAnswer: model?.correctAnswer || '',
            explantion: model?.explantion || '',
            answer1: model?.answer[0] || '',
            answer2: model?.answer[1] || '',
            answer3: model?.answer[2],
            answer4: model?.answer[3],
            answer5: model?.answer[4],
            answer6: model?.answer[5],
            _id: model?._id || '',
        };

        // Log the transformedModel for debugging
        if (!transformedModel?.question) {
            notify('Question is required');
            return;
        }

        if (!transformedModel?.correctAnswer) {
            notify('Correct Answer is required');
            return;
        }

        if (!transformedModel?.explantion) {
            notify('Explanation is required');
            return;
        }


        await axios.put(`https://backend.cloudexamspro.com/api/admin/categorylist/save/${location.state.id_L}/items/${location.state.id}/edit`, transformedModel).then((res) => {
            navigate("/dashboard/questionList")
        }).catch((e) => console.log(e))
        setModel((prevModel) => ({
            ...prevModel,
            question: transformedModel?.question,
            correctAnswer: transformedModel?.correctAnswer,
            explantion: transformedModel?.explantion,
            answer1: transformedModel?.answer1,
            answer2: transformedModel?.answer2,
            answer3: transformedModel?.answer3,
            answer4: transformedModel?.answer4,
            answer5: transformedModel?.answer5,
            answer6: transformedModel?.answer6,
            _id: transformedModel?._id,
        }));
    };

    return (<div>
        <MainHeader />
        <Header screenTitle="Edit Question" buttonList={[
            {
                displayField: () => (
                    // <Button color='error' onClick={() => navigate("/dashboard/questionList")} variant='contained'>   Cancel </Button>
                    <DelButton click={() => navigate("/dashboard/questionList")} text="Cancel" />
                )
            }
        ]} />
        <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
            <ToastContainer />
            <Box >
                <Box className='row p-3'>
                    <div className='col-md-6'>
                        <Typography className='mx-2 ' variant='p'>
                            Question :
                        </Typography>
                        <div className='mt-2'>
                            <input
                                className='form-control w-100'
                                value={model?.question || ''}
                                type='text'
                                name=''
                                id=''
                                onChange={(e) => setModel({ ...model, question: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <Typography className='mx-2 mt-2' variant='p'>
                            Correct Answer:
                        </Typography>
                        <div className='mt-2'>
                            {SelectRender ?
                                <Select mode="multiple" className='w-100' value={correctAnswer} onChange={correctChange} placeholder='Select an option' >
                                    {model?.answer?.map((x, i) => <Select.Option value={x}>{x}</Select.Option>
                                    )}
                                </Select> :
                                <Select className='w-100' value={correctAnswer} onChange={correctChange} placeholder='Select an option' >
                                    {model?.answer?.map((x, i) => <Select.Option value={x}>{x}</Select.Option>
                                    )}
                                </Select>
                            }
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <Typography className='mx-2 mt-2' variant='p'>
                            Explanation :
                        </Typography>
                        <div className='mt-2'>
                            <input
                                className='form-control w-100'
                                value={model?.explantion || ''}
                                type='text'
                                name=''
                                id=''
                                onChange={(e) => setModel({ ...model, explantion: e.target.value })}
                            />
                        </div>
                    </div>

                    {/* <div className='col-md-6'>
                        {model?.answer?.map((option, i) => (
                            <div key={i}>
                                {option !== "" ? (
                                    <>
                                        <Typography className='mx-2 mt-2' variant='p'>
                                            Option {i + 1}:
                                        </Typography>
                                        <input
                                            className='form-control w-100'
                                            value={option || ''}
                                            type='text'
                                            name={`answer${i + 1}`}
                                            id={`option-${i}`}
                                            disabled={isAnyOptionEmpty}
                                            onChange={(e) => {  
                                                const updatedOptions = [...(model?.answer || [])];
                                                updatedOptions[i] = e.target.value;
                                                setModel((prevModel) => ({ ...prevModel, answer: updatedOptions }));
                                            }}
                                        />
                                    </>
                                ) : check? <>
                                        <Typography className='mx-2 mt-2' variant='p'>
                                            Option {i + 1}:
                                        </Typography>
                                        <input
                                            className='form-control w-100'
                                            value={option || ''}
                                            type='text'
                                            name={`answer${i + 1}`}
                                            id={`option-${i}`}
                                            disabled={isAnyOptionEmpty}
                                            onChange={(e) => {  
                                                const updatedOptions = [...(model?.answer || [])];
                                                updatedOptions[i] = e.target.value;
                                                setModel((prevModel) => ({ ...prevModel, answer: updatedOptions }));
                                            }}
                                        />
                                    </>:null}
                            </div>
                        ))}
                    </div> */}



                    
                    <div className='col-md-6'>
    {[1, 2, 3,4,5, 6].map((optionIndex) => (
        <div key={optionIndex}>
            <Typography className='mx-2 mt-2' variant='p'>
                Option {optionIndex}:
            </Typography>
            <input
                className='form-control w-100'
                value={model?.answer[optionIndex - 1] || ''}
                type='text'
                name={`answer${optionIndex}`}
                id={`option-${optionIndex}`}
                disabled={isAnyOptionEmpty}
                onChange={(e) => {
                    const updatedOptions = [...model?.answer];
                    updatedOptions[optionIndex - 1] = e.target.value;
                    setModel((prevModel) => ({ ...prevModel, answer: updatedOptions }));
                }}
            />
        </div>
    ))}
</div>


                    {/* <div className='col-md-6'>
                        {model?.answer?.length >= 3 ? model?.answer?.map((option, i) => (
                            <div key={i}>
                                <Typography className='mx-2 mt-2' variant='p'>
                                    Option {i + 1}:
                                </Typography>
                                <input
                                    className='form-control w-100'
                                    value={option || ''}
                                    type='text'
                                    name={`answer${i + 1}`}
                                    id={`option-${i}`}
                                    // disabled={isAnyOptionEmpty}
                                    onChange={(e) => {
                                        const updatedOptions = [...(model?.answer || [])];
                                        updatedOptions[i] = e.target.value;
                                        setModel((prevModel) => ({ ...prevModel, answer: updatedOptions }));
                                    }}
                                />

                            </div>))
                            : model?.answer?.map((option, i) => (
                                <div key={i}>
                                    <Typography className='mx-2 mt-2' variant='p'>
                                        Option {i + 1}:
                                    </Typography>
                                    <input
                                        className='form-control w-100'
                                        value={option || ''}
                                        type='text'
                                        name={`answer${i + 1}`}
                                        id={`option-${i}`}
                                        disabled={true}
                                        onChange={(e) => {
                                            const updatedOptions = [...(model?.answer || [])];
                                            updatedOptions[i] = e.target.value;
                                            setModel((prevModel) => ({ ...prevModel, answer: updatedOptions }));
                                        }}
                                    />

                                </div>))}
                    </div> */}

                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                        <BsButton isButtonDisabled={disabled} text='submit' onClick={(e) => handleSubmit(e)} />
                    </Box>
                </Box>
            </Box>
        </Box>
    </div>
    )
}

export default EditQuestion
