import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import DButton from './DButton';
import Modal from '@mui/material/Modal';
import { Input } from 'antd';
import { FaDownload  } from "react-icons/fa";
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  bottom: '30%',
  left: '50%',
  transform: 'translate(-50%, -0%)',
  width: 420,
  height: 300,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 6,
};

const ModelExport = (props) => {
  const [csvData, setCsvData] = useState([]);
  const { open, closed, data } = props;

  const handleClose = () => {
    closed(false);
  };

  // const exportToCsv = async () => {
  //   try {
  //     const response = await fetch(`https://backend.cloudexamspro.com/api/admin/categorylist/save/${data?.nestedItemId}/items/csv `, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         // Your request payload here, if needed
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const text = await response.text();
  //     const blob = new Blob([text], { type: 'text/csv' });

  //     // Create a link element and click it to trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'exported-data.csv';
  //     link.click();

  //     // Optionally, set the CSV data to state
  //     const parsedData = parseCsv(text);
  //     setCsvData(parsedData);
  //   } catch (error) {
  //     console.error('Error fetching CSV data:', error);
  //   }
  // };

  // Custom CSV parser function
let handleDownload = async () =>{
  try {
    // Make a request to the backend to get the CSV file
    const response = await fetch(`https://backend.cloudexamspro.com/api/admin/export-to-csv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: data?._id }), // Pass any required parameters
    });

    // Check if the request was successful
    if (!response.ok) {
      toast.error('Error downloading CSV file', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }else{
      handleClose()
      toast.success('File Download Successfuly', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create a temporary link element
    const link = document.createElement('a');

    // Create a Blob URL for the Blob data
    const blobUrl = window.URL.createObjectURL(blob);

    // Set the link's href attribute to the Blob URL
    link.href = blobUrl;

    // Set the link's download attribute to the desired file name
    link.download = 'export.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);

    // Revoke the Blob URL to free up resources
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading CSV file:', error.message);
  }
}

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 className="text-center mb-2" variant="h6">
            {data?.name}
          </h3>
          <Input disabled={true} value={data?.name} style={{ height: '34px' }} placeholder="Search Bar" type="search" />
          <img className="mt-2 table_img" width={'20%'} height={"32%"} src={data?.img} alt="Preview" />
          <br />
          <div className='text-center my-2'>
          {/* <DButton click={handleDownload} text="Download" icon={<FaDownload  />} /> */}
          <Button onClick={handleDownload} className='px-4 ' style={{ marginTop:"1px" ,backgroundColor:"#235D97", color:"#fff", borderRadius:"3px", height:"32px",textTransform: 'capitalize'}} type="primary">Download <FaDownload style={{marginLeft:"5px"}} /> </Button >
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModelExport;
