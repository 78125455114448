import { Button, Skeleton } from 'antd'
// import HeaderButton from '../../Comp/HeaderButton'
import { ToastContainer } from 'react-toastify'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import DButton from '../../Comp/DButton'
import { GetApi } from '../../Api/BaseHandle'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../Comp/Pagination'
import '../../Comp/responsiveTable.css'; // Import the CSS


const UserView = () => {
  let [data, setData] = useState([])
  let [flag, setflag] = useState(false)
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(20);
  let navigate = useNavigate()
  let cols = [
    {
      name: "ID",
      key: "userId"
    },
    {
      name: "Name",
      key: "name"
    },
    {
      name: "Gender",
      key: "gender"
    },
    {
      name: "Email",
      key: "email"
    },
    {
      name: "Action",
      displayFeild: (e) => (
        <>
          <DButton text="View" click={() => navigate("/dashboard/userDetail", { state: { data: e.userplay } })} />
          {/* <Button type="primary" onClick={() => navigate("/dashboard/userDetail", { state: {name:e.userPlay,data: e.submit} })}>View</Button> */}
          {/* <Button className='mx-2' type="primary" danger onClick>Delete</Button> */}
        </>
      ),
    },
  ]

  const GetData = () => {
    GetApi("/user/get/quizz/user").then((res) => {
      setData(res?.data?.data)
      let setedData = res?.data?.data?.flatMap((x, i) =>
        x.userid
      )

    }).catch((e) => console.log(e))

  }

  useEffect(() => {
    GetData()
  }, [])


  const indexoflastpost = currentpage * postperpge;
  const indexofFirstpost = indexoflastpost - postperpge;
  const currentpost = data?.slice(indexofFirstpost, indexoflastpost);
  const paginate = (pageNumber) => setcurrentpage(pageNumber);

  return (
    <>
      <div>
        <MainHeader />
        <Header screenTitle="Users List" />
        <ToastContainer />
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {data ? (
              data?.length === 0 ? (
                flag ? (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-bordered text-center"
                      style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                    >
                      <thead>
                        <tr>
                          {cols &&
                            cols.map((x, i) => {
                              return <th style={{ backgroundColor: "#235D97", color: "#fff" }} key={i}>{x.name}</th>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="6">No Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-bordered text-center"
                      style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                    >
                      <tr>
                        {cols &&
                          cols.map((x, i) => {
                            return <th style={{ backgroundColor: "#235D97", color: "#fff" }} key={i}>{x.name}</th>;
                          })}
                      </tr>
                      <tbody>
                        {cols.map((x, i) => (
                          <tr key={i}>
                            <td key={i} colSpan="6"><Skeleton.Input style={{ width: '90vh' }} active animation="wave" /> </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                )
              ) : (
                <div className="table-responsive">
                  <table
                    className="table table-striped table-bordered text-center"
                    style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                  >
                    <thead>
                      <tr>
                        {cols &&
                          cols.map((x, i) => {
                            return <th style={{ backgroundColor: "#235D97", color: "#fff" }} key={i}>{x.name}</th>;
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {currentpost.map((item, key) => (
                        <tr key={key} className='table_img'>
                          {cols.map((col, index) => {
                            // Determine the value to display
                            let displayValue;
                            if (col.displayFeild) {
                              displayValue = col.displayFeild(item);
                            } else if (col.key === 'gender') {
                              displayValue = item.gender ? item.gender : "Not Selected";
                            } else if (col.key === 'amount' || col.key === 'price') {
                              displayValue = `$${item[col.key]}`;
                            } else {
                              // Access the item's property using the key
                              displayValue = item[col.key];
                            }

                            return (
                              <td style={{ verticalAlign: 'middle' }} key={index}>
                                {displayValue}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            ) : null}
          </div>
        </>
        <Pagination perpge={postperpge} total={data?.length} paginate={paginate} />
      </div>
    </>
  )
}

export default UserView
