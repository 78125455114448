import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './Router/AppRouter';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
