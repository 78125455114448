import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
// import BsInp from '../../Comp/BsInp'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import UploadImage from '../../Comp/UploadImage'
import convertToBase64 from '../../Comp/Converter'
import BsButton from '../../Comp/BsButton'
import { PutApi } from '../../Api/BaseHandle'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom'
import DelButton from '../../Comp/DelButton'

const EditCategory = () => {
    const [model, setModel] = useState({})
    const [disable, setdisable] = useState(false);
    const [icon, setIcon] = useState();
    const [url, setUrl] = useState("");
    const navig = useNavigate()
    const loca = useLocation()

    useEffect(() => {
        setModel({ ...loca.state })
        setUrl(loca.state.img)
        setIcon(loca.state.img)
    }, [])


    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setUrl(base64);
        setIcon(e.target.files[0]);
    };

    const subMitBtn = (e) => {
        if (!model.name) {
            toast.error('Name Requierd', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        if (!model.questions) {
            toast.error('Number Requierd', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }

        const formData = new FormData();
        formData.append("name", model.name);
        formData.append("questions", model.questions);
        formData.append("timer", model.timer);
        formData.append("img", icon);
        setdisable(true)
        PutApi("/admin/allcategory", loca.state._id, formData)
            .then((succ) => {
                navig("/dashboard/listCategories")
                setdisable(false)
            })
            .catch((err) => {
                setdisable(false)
                console.log(err)
            })

    }


    return (
        <div className=''>
            <MainHeader />
            <Header screenTitle="Create Categories" buttonList={[
                {
                    displayField: () => (
                        <DelButton click={() => navig("/dashboard/listCategories")} text="Cancel" />
                    )
                }
            ]} />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
                <Box >
                    <ToastContainer />
                    <Box className='row p-3'>

                        <div className="col-md-6">
                            <Typography className='text-center ' variant='p'> Name : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.name || ""} type="text" name="" id="" onChange={(e) => setModel({ ...model, name: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <Typography className='text-center ' variant='p'> Total Question : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.questions || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, questions: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <Typography className='text-center ' variant='p'> Timer : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.timer || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, timer: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-6 mt-3">
                            <UploadImage onUpload={onUpload} />

                            {url && url.includes("storage") ? (
                                <img
                                    className='table_img'
                                    src={`${url}`}
                                    alt="Icon"
                                    style={{ width: "60px", height: "55px" }}
                                />
                            ) : (
                                <img src={url} alt="Icon" className='table_img' style={{ width: "60px", height: "55px" }} />
                            )}
                        </div>

                        <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                            <BsButton isButtonDisabled={disable} text="submit" onClick={subMitBtn} />
                        </Box>


                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default EditCategory