import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import MainHeader from '../../Comp/MainHeader'
import HeaderButton from '../../Comp/HeaderButton'
import { Box, Typography } from '@mui/material'
import Header from '../../Comp/Header'
import BsTable from '../../Comp/BsTable'
import DButton from '../../Comp/DButton'
// import { Button } from 'bootstrap'
import { Button } from 'antd'
import { DeleteApi, GetApi } from '../../Api/BaseHandle'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom'
import DelButton from '../../Comp/DelButton'
import Pagination from '../../Comp/Pagination'

const PlansList = () => {
  let navigation = useNavigate()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(10);

  let GetData = () => {
    GetApi("/admin/pro/getall")
      .then((succ) => {
        setData([...succ.data.data])
      })
      .catch(e => console.log(e))
  }

  let Delete = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        DeleteApi("/admin/pro/get", e._id).then((res) => {
          let filData = data.filter((x) => x._id !== e._id)
          setData(filData)
        }).catch((e) => console.log(e))
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }
    });

  }

  let edit = (e) => {
    navigation("/dashboard/editplans", {
      state: e
    })
  }

  useEffect(() => {
    GetData()
  }, [])


  const indexoflastpost = currentpage * postperpge;
  const indexofFirstpost = (currentpage - 1) * postperpge;
  const currentpost = (Array.isArray(data) && data.length > 0) && data?.slice(indexofFirstpost, Math.min(indexoflastpost, data.length));
  const paginate = (pageNumber) => {
    setcurrentpage(pageNumber);
  };

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Plan List " />
      <Box>
        <Box className="px-5 " >
          <ToastContainer />
        </Box>
        <Box>
        </Box>
        {open ?
          <Box sx={{ height: "55%" }} className="d-flex justify-content-center ">
            {/* <img className='w-25' src={Img} alt="Centered Image" /> */}
            <Box>
              <Typography variant='h6' className='text-dark pt-1 fs-4 px-2'>
                Data Not Found...
              </Typography>
              <Typography variant='p' className='text-dark pt-1 fs-6 px-2'>
                You have to Create First
              </Typography >
            </Box>
          </Box>
          :
          <BsTable data={currentpost} cols={[
            {
              name: "Name",
              key: "name"
            },
            {
              name: "Price",
              key: "price"
            },
            {
              name: "Credits",
              key: "points"
            },
            {
              name: "Action",
              displayFeild: (e) => (
                <>
                  {/* <Button type="primary" onClick={()=>edit(e)}>edit</Button> */}
                  <DButton click={() => edit(e)} />
                  {/* <Button className='mx-2' type="primary" danger onClick={()=>Delete(e)}>Delete</Button> */}
                  {/* <DelButton click={()=>Delete(e)}/> */}
                </>
              )
            },

          ]} />
        }
      </Box>
      <BsTable />
      <Pagination perpge={postperpge} total={data.length} paginate={paginate} />
    </div>
  )
}

export default PlansList
