import React, { useEffect, useState } from 'react'
import { PutApi } from '../../Api/BaseHandle'
import { useLocation, useNavigate } from 'react-router-dom'
import MainHeader from '../../Comp/MainHeader'
import Header from '../../Comp/Header'
import { Box, Button } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { Typography } from 'antd'
import BsButton from '../../Comp/BsButton'
import DelButton from '../../Comp/DelButton'

const EditPlans = () => {
  let [model, setModel] = useState([])
  let navigation = useNavigate()
  let location = useLocation()
  let notify = (message) => { toast.error(message) }
  useEffect(() => {
    setModel(location.state)
  }, [])

  let sendData = () => {

    if (!model.name) {
      notify('Name is required');
      return;
    }
    if (!model.price) {
      notify('Price is required');
      return;
    }

    PutApi("admin/pro/get", model._id, model).then((res) => {
      navigation("/dashboard/planlist")
    }).catch((e) => console.log(e))
  }

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Edit Plan" buttonList={[
        {
          displayField: () => (
            // <Button color='error' onClick={() => navigation("/dashboard/planList")} variant='contained'>   Cancel </Button>
            <DelButton click={() => navigation("/dashboard/planList")} text="Cancel" />
          )
        }
      ]} />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
        <Box >
          <ToastContainer />
          <Box className='row p-3'>

            <div className="col-md-6">
              <Typography className='' variant='p'> Name : </Typography>
              <div className='mt-2'>
                <input className="form-control w-100" value={model.name || ""} type="text" name="" id="" onChange={(e) => setModel({ ...model, name: e.target.value })} />
              </div>
            </div>

            <div className="col-md-6">
              <Typography className='' variant='p'> Price : </Typography>
              <div className='mt-2'>
                <input className="form-control w-100" value={model.price || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, price: e.target.value })} />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <Typography className='' variant='p'> Credits : </Typography>
              <div className='mt-2'>
                <input className="form-control w-100" value={model.points || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, points: e.target.value })} />
              </div>
            </div>

            <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
              <BsButton text="submit" onClick={sendData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default EditPlans
