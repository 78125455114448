import React, { useEffect, useState } from 'react'
import Pagination from '../../Comp/Pagination' // Ensure this is imported

import MainHeader from '../../Comp/MainHeader'
import Header from '../../Comp/Header'
import HeaderButton from '../../Comp/HeaderButton'
import BsTable from '../../Comp/BsTable'
import { DeleteApi, GetApi, PostApi } from '../../Api/BaseHandle'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { Box, Button, Typography } from '@mui/material'
import DButton from '../../Comp/DButton'
// import SelectSearch from '../../Comp/SelectSearch'

const QuestionList = () => {
  
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(10);

  const [listCategoryData, setListCategotyData] = useState([])
  const [listFilter, setListFilter] = useState([])
  const [list, setList] = useState([])
  const [open, setOpen] = useState(false)
  let [data, setData] = useState([])
  const [id, setid] = useState()
  const navigate = useNavigate()

  let GetData = () => {
    GetApi("/admin/allCategory")
      .then((succ) => {
        setData([...succ.data.data])
      })
      .catch((errr) => {
        console.log(errr)

      })
  }

  useEffect(() => {
    GetData()
  }, [])

  // Pagination logic
  const indexoflastpost = currentpage * postperpge;
  const indexofFirstpost = (currentpage - 1) * postperpge;
  const currentpost = data.slice(indexofFirstpost, indexoflastpost);

  const paginate = (pageNumber) => setcurrentpage(pageNumber);

  const sendData = (e, val) => {
    PostApi("/admin/categoryListAll/name/find", { name: e })
      .then((succ) => {
        if (!succ?.data?.data) {
          setOpen(true)
        }
        setListFilter([...succ?.data?.data])
        setList([...succ?.data?.data])
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const deleteBtn = (e) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        DeleteApi("/admin/allcategory", e._id).then((succ) => {
          let filtrr = data.filter(x => x._id != e._id)
          setListFilter([...filtrr])
          setList([...filtrr])
          setData([...filtrr])
        }).catch((e) => {
          toast.error('Failed', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }

    })
  }




  const sendDataVal = (e) => {
    const searchTerm = e.toLowerCase();

    let filt;

    if (searchTerm === "") {
      filt = [...listFilter];
    } else {
      filt = listFilter.filter((x) => x.name.toLowerCase().includes(searchTerm));
    }

    setList([...filt]);

    // Check if the filtered list is empty
    if (filt.length === 0) {
      setOpen(true); // Set the state variable to indicate that no data is available
    } else {
      setOpen(false); // Reset the state variable
    }

  }
  const navig = useNavigate()

  const editBtn = (e) => {
    navig("/dashboard/editCategories/", {
      state: e
    })
  }
  const showData = (e) => {
    navigate("/dashboard/createquestion", {
      state: e.name
    })
  }

  return (
    <div>
      <MainHeader />
      <Header screenTitle="List Categories" />
      <ToastContainer />
      <Box className="px-5 " >
        {/* <SelectSearch path="/dashboard/createCategories" data={listCategoryData} sendDataVal={sendDataVal} sendData={sendData} /> */}
      </Box>
      <Box>
      </Box>

      {open ?
        <Box sx={{ height: "55%" }} className="d-flex justify-content-center ">
          {/* <img className='w-25' src={Img} alt="Centered Image" /> */}
          <Box>
            <Typography variant='h6' className='text-dark pt-1 fs-4 px-2'>
              Data Not Found...
            </Typography>
            <Typography variant='p' className='text-dark pt-1 fs-6 px-2'>
              You have to Create First
            </Typography>
          </Box>
        </Box>
        : <BsTable data={currentpost} cols={[
          {
            name: "Catagories Name",
            key: "name"
          },
          {
            name: "Image",
            img: "img"
          },
          {
            name: "Action",
            displayFeild: (e) => (
              <>
                {/* <Button variant='contained' color='primary' onClick={() => showData(e)} >Create Questions</Button> */}
                <DButton text="Create Questions" click={() => showData(e)} />
              </>
            ),
          },
        ]} />

      }
    <Pagination perpge={postperpge} total={data.length} paginate={paginate} />
    </div>
  )
}

export default QuestionList