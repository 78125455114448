import React, { useEffect, useState } from 'react'
import MainHeader from '../../Comp/MainHeader'
import Header from '../../Comp/Header'
import { Box, Button } from '@mui/material'
import { Typography } from 'antd'
import BsButton from '../../Comp/BsButton'
import { PutApi } from '../../Api/BaseHandle'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DelButton from '../../Comp/DelButton'

const EditAdmin = () => {
  let [model,setModel] = useState([])
  let navigation = useNavigate()
  let location = useLocation()
  useEffect(()=>{
      setModel(location.state)
  },[])

  let sendData = () =>{
    if (!model.name) {
      toast.error('Name is required');
      return;
    } 
    
    if (!model.email) {
      toast.error('Email is required');
      return;
    } 
    
    if (!model.password) {
      toast.error('Password is required');
      return;
      
    }  
     PutApi("/admin/user/update",model._id, model ).then((res)=>{
      navigation("/dashboard/adminList")
  }).catch((e)=>console.log(e))
  }


  return(
     <div>
  <MainHeader />
 <Header screenTitle="Edit Admin"  buttonList={[
        {
          displayField: () => (
            // <Button color='error' className='delete_btn' onClick={() => navigation("/dashboard/adminList")} variant='contained'>Cancel</Button>
            <DelButton click={() => navigation("/dashboard/adminList")} text="Cancel"/>
          )
        }
      ]}/>
 <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
   <Box >
     <ToastContainer />
     <Box className='row p-3'>

       <div className="col-md-6">
         <Typography  variant='p'> Name : </Typography>
         <div className='mt-2'>
           <input className="form-control w-100" value={model.name || ""} type="text" name="" id="" onChange={(e) => setModel({ ...model, name: e.target.value })} />
         </div>
       </div>

       <div className="col-md-6">
         <Typography variant='p'> Email : </Typography>
         <div className='mt-2'>
           <input className="form-control w-100" value={model.email || ""} type="text" name="" id="" onChange={(e) => setModel({ ...model, email: e.target.value })} />
         </div>
       </div>

       <div className="col-md-6">
         <Typography className='mt-3' variant='p'> Password : </Typography>
         <div className='mt-2'>
           <input className="form-control w-100"  type="password" name="" id="" onChange={(e) => setModel({ ...model, password: e.target.value })} />
         </div>
       </div>
       <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
         <BsButton text="submit" onClick={sendData} />
       </Box>
     </Box>
   </Box>
 </Box>
</div>
  )
 
}

export default EditAdmin
