import React, { useEffect, useState } from 'react'
import MainHeader from '../../Comp/MainHeader'
import Header from '../../Comp/Header'
import HeaderButton from '../../Comp/HeaderButton'
import BsTable from '../../Comp/BsTable'
import DButton from '../../Comp/DButton'
import DelButton from '../../Comp/DelButton'
import { DeleteApi, GetApi } from '../../Api/BaseHandle'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { Box } from '@mui/material'
import Pagination from '../../Comp/Pagination'

const ListCategory = () => {
  let [data, setData] = useState([])
  const [listFilter, setListFilter] = useState([])
  const [list, setList] = useState([])
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(10);

  let GetData = () => {
    GetApi("/admin/allCategory")
      .then((succ) => {
        setData([...succ.data.data])
      })
      .catch((errr) => {
        console.log(errr)

      })
  }

  useEffect(() => {
    GetData()
  }, [])


  const deleteBtn = (e) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        DeleteApi("/admin/allcategory", e._id).then((succ) => {
          let filtrr = data.filter(x => x._id != e._id)
          setListFilter([...filtrr])
          setList([...filtrr])
          setData([...filtrr])          
        }).catch((e) => {
          toast.error('Failed', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }

    })
  }

  const navig = useNavigate()

  const editBtn = (e) => {
    navig("/dashboard/editCategories/", {
      state: e
    })
  }


  const indexoflastpost = currentpage * postperpge;
const indexofFirstpost = (currentpage - 1) * postperpge; 

const currentpost = (Array.isArray(data) && data.length > 0) && data?.slice(indexofFirstpost, Math.min(indexoflastpost, data.length));


const paginate = (pageNumber) => {
  setcurrentpage(pageNumber);
};

  return (
    <div>
      <MainHeader />
      <Header screenTitle="List Categories" />
      <ToastContainer />
      <Box>
        <HeaderButton path="/dashboard/createCategories"/>
        </Box>
        
        <BsTable data={currentpost}cols={[
          {
            name: "Catagories Name",
            key: "name"
          },
          {
            name: "Image",
            img: "img"
          },
          {
            name: "Action",
            displayFeild: (e) => (
              <>
                {/* <Button type="primary" onClick={() => editBtn(e)}>edit</Button> */}
                 <DButton click={() => editBtn(e)}/>
                {/* <Button className='mx-2' type="primary" danger onClick={() => deleteBtn(e)}>Delete</Button> */}
                <DelButton click={() => deleteBtn(e)}/>
              </>
            ),
          },
        ]} />
        
      
        <Pagination perpge={postperpge} total={data.length} paginate={paginate} />

    </div>
  )
}

export default ListCategory