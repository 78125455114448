import React, { useState } from 'react'
import BsTable from '../../Comp/BsTable'
import { Typography } from 'antd'
import HeaderButton from '../../Comp/HeaderButton'
import { ToastContainer } from 'react-toastify'
import { Box } from '@mui/material'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import { GetApi } from '../../Api/BaseHandle'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DButton from '../../Comp/DButton'

const Setting = () => {
  let [open, setopen] = useState(false)
  let [data, setdata] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    GetApi("/admin/setting/get").then((res) => {
      setdata(res.data.data)
    }).catch((e) => console.log(e))
  }, [])


  const handleEdit = (e) => {
    navigate("/dashboard/editSetting", {
      state: e
    })
  }
  return (
    <div>
      <MainHeader />
      <Header screenTitle="Setting List" />
      <Box>
        <Box className="px-5 " >
          <ToastContainer />
        </Box>
        <Box>
        </Box>
        {open ?
          <Box sx={{ height: "55%" }} className="d-flex justify-content-center ">
            <Box>
              <Typography variant='h6' className='text-dark pt-1 fs-4 px-2'>
                Data Not Found...
              </Typography>
              <Typography variant='p' className='text-dark pt-1 fs-6 px-2'>
                You have to Create First
              </Typography >
            </Box>
          </Box>
          :
          <BsTable data={data} cols={[
            {
              name: "Message",
              key: "message"
            },
            {
              name: "Action",
              displayFeild: (e) => (
                <>
                  {/* <Button type="primary" onClick={() => handleEdit(e)} >edit</Button> */}
                  <DButton click={() => handleEdit(e)}/>
                </>
              )
            },
            
          ]} />
        }
      </Box>
      <BsTable />
    </div>
  )
}

export default Setting
// https://backend.cloudexamspro.com/api/admin/setting/get