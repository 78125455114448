import { Box } from '@mui/material';
import Header from '../../Comp/Header';
import { Select, Typography } from 'antd';
import BsButton from '../../Comp/BsButton';
import 'react-toastify/dist/ReactToastify.css';
import MainHeader from '../../Comp/MainHeader';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { GetApi, PostApi } from '../../Api/BaseHandle';
import { useLocation, useNavigate } from 'react-router-dom';

const QuestionCreate = () => {
  const [model, setModel] = useState({ question: '', Type: "String", correctAnswer: [], explantion: '', answer1: '', answer2: '' });
  const [idL, setidL] = useState("");
  const [text, settext] = useState(false);
  const [correctAnswer, setcorrectAnswer] = useState();
  const [disable, setdisable] = useState(false);
  const notify = (message) => toast.error(message);
  let location = useLocation()
  let navigate = useNavigate()
  let { state } = location

  const checker = (e) => {
    settext(e);
    if (e) {
      setModel({
        ...model,
        Type: "boolean",
        answer1: "false",
        answer2: "true",
      });
    } else {
      setModel({
        ...model,
        Type: "String",
        answer1: "",
        answer2: "",
      });
    }
  }

  useEffect(() => {
    PostApi("/admin/categoryListAll/name/find", { name: state }).then((res) => {
      setidL(res?.data?.data[0]?._id)

    }).catch((e) => console.log(e))
  }, [])

  const correctChange = (e) => {
    setcorrectAnswer(e);
    setModel({ ...model, correctAnswer: e })
  };


  const handleSubmit = () => {
    // Basic validation
    if (!model?.question) {
      notify('Question is required');
      return;
    }

    if (!model?.correctAnswer) {
      notify('Correct Answer is required');
      return;
    }

    if (!model?.explantion) {
      notify('Explanation is required');
      return;
    }

    setdisable(true)
    // return
    PostApi(`/admin/categorylist/save/${idL}/items`, model).then((res) => {
      setdisable(false)
      setModel({ question: '', Type: "String", correctAnswer: [], explantion: '', answer1: '', answer2: '' });
      setcorrectAnswer()
      toast.success("Question Created Successfully");

      // navigate("/dashboard/questionC_List")
    }).catch((e) => console.log(e))
  };

  const getOptions = () => {
    // Filter the options to exclude empty ones
    return ['answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6'].filter(key => model[key]).map(key => (
      <Select.Option key={key} value={model[key]}>{model[key]}</Select.Option>
    ));
  };

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Create Question" />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
        <Box>
          <ToastContainer />
          <Box className='row p-3'>
            <div className='col-md-6'>
              <Typography className='mx-2 ' variant='p'>
                Question :
              </Typography>
              <div className='mt-2'>
                <input
                  className='form-control w-100'
                  value={model?.question || ''}
                  type='text'
                  name=''
                  id=''
                  onChange={(e) => setModel({ ...model, question: e.target.value })}
                />
              </div>
            </div>
            {text ? (<div className='col-md-6'>
              <Typography className='mx-2 mt-2' variant='p'>
                Correct Answer:
              </Typography>
              <div className='mt-2'>
                <Select className='w-100' value={correctAnswer} onChange={correctChange} placeholder='Select an option' >
                  <Select.Option value="true">true</Select.Option>
                  <Select.Option value="false">false</Select.Option>
                </Select>
              </div>
            </div>) : (<div className='col-md-6'>
              <Typography className='mx-2 mt-2' variant='p'>
                Correct Answer:
              </Typography>
              <div className='mt-2'>
                <Select mode="multiple" className='w-100' value={correctAnswer} onChange={correctChange} placeholder='Select an option'>
                  {getOptions()}
                </Select>
              </div>
            </div>)
            }
            <div className='col-md-6'>
              <Typography className='mx-2 mt-2' variant='p'>
                Explanation :
              </Typography>
              <div className='mt-2'>
                <textarea
                  className='form-control w-100'
                  value={model?.explantion || ''}
                  type='text'
                  name=''
                  id=''
                  onChange={(e) => setModel({ ...model, explantion: e.target.value })}
                />
              </div>
            </div>
            <div className='col-md-6'>
              {text ? (
                <>
                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 1 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      defaultValue={"false"}
                      disabled={true}
                      onChange={(e) => setModel({ ...model, answer1: "false" })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 2 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      defaultValue={"true"}
                      onChange={(e) => setModel({ ...model, answer2: "true" })}
                      disabled={true}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>
                </>
              ) : (
                <>
                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 1 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer1 || ''}
                      onChange={(e) => setModel({ ...model, answer1: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 2 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer2 || ''}
                      onChange={(e) => setModel({ ...model, answer2: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 3 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer3 || ''}
                      onChange={(e) => setModel({ ...model, answer3: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 4 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer4 || ''}
                      onChange={(e) => setModel({ ...model, answer4: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 5 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer5 || ''}
                      onChange={(e) => setModel({ ...model, answer5: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>
                  <Typography className='mx-2 mt-2 ' variant='p'>
                    Options 6 :
                  </Typography>
                  <div className='mt-2'>
                    <input
                      className='form-control w-100'
                      value={model?.answer6 || ''}
                      onChange={(e) => setModel({ ...model, answer6: e.target.value })}
                      type='text'
                      name=''
                      id=''
                    />
                  </div>

                </>
              )}
            </div>

            <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
              <BsButton isButtonDisabled={disable} text='submit' onClick={handleSubmit} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default QuestionCreate;
