import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import UploadImage from '../../Comp/UploadImage'
import convertToBase64 from '../../Comp/Converter'
import BsButton from '../../Comp/BsButton'
import { PostApi } from '../../Api/BaseHandle'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { IoImagesSharp } from "react-icons/io5";

const CreateCategory = () => {
    const [model, setModel] = useState({})
    const [icon, setIcon] = useState();
    const [disable, setdisable] = useState(false)
    const [url, setUrl] = useState();
    const navig = useNavigate()

    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setUrl(base64);
        setIcon(e.target.files[0]);
    };

    const subMitBtn = () => {
        if (!model.name) {
            toast.error('Name Requierd', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        if (!model.questions) {
            toast.error('Number Requierd', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        if (!model.timer) {
            toast.error('Timer Required', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        if (!icon) {
            toast.error('Image Requierd', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
       setdisable(true)
        const formData = new FormData();
        formData.append("name", model.name);
        formData.append("timer", model.timer);
        formData.append("questions", model.questions);
        formData.append("img", icon);
        
        setdisable(true)
        PostApi("admin/allcategory/save", formData)
        .then((succ) => {
            navig("/dashboard/listCategories")
            setdisable(false)
        })
        .catch((err) => {
            console.log(err)
            setdisable(false)
            })
    }
    return (
        <div className='px-1'>
            <MainHeader />

            <Header screenTitle="Create Categories" />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
                <Box >
                    <ToastContainer />
                    <Box className='row p-3'>

                        <div className="col-md-6">
                            <Typography className='text-center ' variant='p'> Name : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.name || ""} type="text" name="" id="" onChange={(e) => setModel({ ...model, name: e.target.value })} />
                            </div>
                        </div>

                        <div className="col-md-6 mt-2">
                            <Typography className='text-center ' variant='p'> Total Question : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.questions || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, questions: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <Typography className='text-center ' variant='p'> Timer / in second : </Typography>
                            <div className='mt-2'>
                                <input className="form-control w-100" value={model.timer || ""} type="number" name="" id="" onChange={(e) => setModel({ ...model, timer: e.target.value })} />
                            </div>
                        </div>
                     
                        <div className="col-md-6 mt-2 table_img">
                            <UploadImage onUpload={onUpload} />                     
                            {url && url.slice(0, 7) === "uploads" ? (
                                <img 
                                    className="table_img"
                                    src={`${url}`}
                                    alt="Icon"
                                    style={{ width: "100px", height: "50px" }}
                                />
                            ) : 
                            (
                                url ? <img  className="table_img"src={url} alt="Icon" style={{ width: "60px", height: "60px"  }} />
                            :<IoImagesSharp size={50} />)}
                        </div>

                        <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                            <BsButton isButtonDisabled={disable}text="submit" onClick={subMitBtn} />
                        </Box>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default CreateCategory

// {url && url.slice(0, 7) === "uploads" && (
//     <img
//         src={`${url}`}
//         alt="Icon"
//         style={{ width: "100px", height: "50px" }}
//     />
// ) }