import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'; // Importing ReactQuill
import 'react-quill/dist/quill.snow.css'; // Importing Quill styles
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import { Box } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import BsButton from '../../Comp/BsButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { PostApi } from '../../Api/BaseHandle'
import DelButton from '../../Comp/DelButton'

const EditRule = () => {
  const [model, setModel] = useState({ name: '', condition: [''] });
  const location = useLocation()
  const navigate = useNavigate()
  let { state } = location

  const addcondition = () => {
    setModel({ ...model, condition: [...model.condition, ''] });
  };

  const handleconditionChange = (index, value) => {
    const updatedcondition = [...model.condition];
    updatedcondition[index] = value;
    setModel({ ...model, condition: updatedcondition });
  };

  useEffect(() => {
    setModel(state || { name: state.name, condition: state.condition });
  }, [state])

  const handleFirstConditionChange = (value) => {
    const updatedCondition = [...model.condition];
    updatedCondition[0] = value; // Update only the first element
    setModel({ ...model, condition: updatedCondition });
  };

  const removeCondition = (index) => {
    const updatedConditions = [...model.condition];
    updatedConditions.splice(index, 1);
    setModel({ ...model, condition: updatedConditions });
  };
  
  const handleSubmit = () => {
    PostApi("/admin/condition", {
      name: state.name,
      description: model.condition
    }).then((res) => {
      navigate("/dashboard/rulesList")
    }).catch((e) => console.log(e))

  }
  return (
    <div>
      <MainHeader />
      <Header screenTitle="Edit Page" buttonList={[
        {
          displayField: () => (
            <DelButton click={() => navigate("/dashboard/rulesList")} text="Cancel" />
          )
        }
      ]} />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className="shadow m-auto mt-2">
        <Box>
          <ToastContainer />
          <Box className="row p-3">
            <div className="col-12 my-2">
              <Typography className='mx-1' variant="p">
                Name :
              </Typography>
              <div className="mt-2">
                <input
                  className="form-control w-100"
                  value={model.name}
                  disabled={true}
                  type="text"
                  onChange={(e) => setModel({ ...model, name: e.target.value })}
                />
              </div>
            </div>

            <div className="col-12 my-2">
              <Typography className='mx-1' variant="p">
                Description :
              </Typography>
              <div className="mt-2">
                <ReactQuill
                  value={model.condition[0]}
                  onChange={(value) => handleFirstConditionChange(value)}
                />
              </div>
            </div>


            <Box sx={{ display: 'flex', justifyContent: 'start' }} className="py-3">
              <BsButton text="Submit" onClick={handleSubmit} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default EditRule
