import { Box } from '@mui/material';
import { Typography } from 'antd';
import React, { useState } from 'react';
import Header from '../../Comp/Header';
import MainHeader from '../../Comp/MainHeader';
import BsButton from '../../Comp/BsButton';
import { PostApi } from '../../Api/BaseHandle';
import { useNavigate } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';

const CreateAdmin = () => {
  const [model, setModel] = useState({ name: '', email: '', password: '',isAdmin:true });
  const [text, settext] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const [disable, setdisable] = useState(false);
  let navigate = useNavigate()

  const checker = (e) => {
    settext(e);
    setModel({ ...model, isAdmin: e });
    // setmyPassord()
  };
  const handleChangePassword = () => {
    if (showpassword) {
      setshowpassword(false)
    } else {
      setshowpassword(true)
    }
  }
  const validateForm = () => {
    let isValid = true;

    if (!model.name) {
      toast.error('Name is required');
      return;
    }

    if (!model.email) {
      toast.error('Email is required');
      return;
    }

    if (!model.password) {
      toast.error('Password is required');
      return;

    }
    return isValid;
  };

  const senddata = () => {
    if (validateForm()) {
      setdisable(true)
      PostApi('admin/user/create', model)
      .then((res) => {
          setdisable(false)
          navigate("/dashboard/adminList")
        })
        .catch((e) => {setdisable(false)});
    }
  };

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Create Admin" />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
        <ToastContainer />
        <Box>
          <Box className='row p-3'>
            <div className="col-md-6">
              <Typography className='' variant='p'> Name : </Typography>
              <div className='mt-2'>
                <input
                  className="form-control w-100"
                  value={model.name}
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setModel({ ...model, name: e.target.value })}
                />
              </div>
            </div>

            <div className="col-md-6">
              <Typography className='' variant='p'> Email : </Typography>
              <div className='mt-2'>
                <input
                  className="form-control w-100"
                  value={model.email}
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setModel({ ...model, email: e.target.value })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <Typography className='mt-3' variant='p'> Password : </Typography>
              <div className='mt-2 d-flex relative-position'>
                <input
                  className="form-control w-100"
                  value={model.password || ""}
                  type={showpassword ? "text" : "password"}
                  name="password"
                  id=""
                  onChange={(e) => setModel({ ...model, password: e.target.value })}
                />
                <button className="hide_btn" onClick={handleChangePassword}>
                  {showpassword ? <EyeInvisibleOutlined style={{color: "#14213d"}} /> : <EyeOutlined style={{color: "#14213d"}}/>}
                </button>
              </div>
            </div>
            <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
              <BsButton isButtonDisabled={disable} text="submit" onClick={senddata} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateAdmin;

