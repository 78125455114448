
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';
import Header from '../../Comp/Header';
import MainHeader from '../../Comp/MainHeader';
import { GetApi, PostApi } from '../../Api/BaseHandle';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import DButton from '../../Comp/DButton';
import Pagination from '../../Comp/Pagination'

const RulesList = () => {
  const [list, setList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(10);

  const navigate = useNavigate();

  let cols = [
    { name: "Name", key: "name" },
    {
      name: "Action",
      displayField: (e) => (
        <>
          {/* <Button type="primary" onClick={() => edit(e)}>Edit</Button> */}
          <DButton click={() => edit(e)}/>
        </>
      )
    },
  ];

  const renderDescription = (descriptionArray, index) => {
    const initialPoints = descriptionArray.slice(0, 2);
    const remainingPoints = descriptionArray.slice(2);

    return (
      <>
        <ol>
          {initialPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
          {remainingPoints.length > 0 && (
          <>
            {expandedRows.includes(index) && (<>
                  {remainingPoints.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}                           
                  </>
            )}
            <Button type="link" onClick={() => toggleRowExpansion(index)}>
              {expandedRows.includes(index) ? 'Hide Points' : 'Load More'}
            </Button>
          </>
        )}
        </ol>
        
      </>
    );
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((row) => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  useEffect(() => {
    PostApi("/admin/get/byname")
      .then((res) => {
        setList(res?.data?.data);
      })
      .catch((e) => console.log(e));
  }, []);

  let edit = (e) => {
    navigate("/dashboard/editRules", {
      state: e
    });
  };

  const indexoflastpost = currentpage * postperpge;
  const indexofFirstpost = (currentpage - 1) * postperpge;
const currentpost = (Array.isArray(list) && list.length > 0) && list?.slice(indexofFirstpost, Math.min(indexoflastpost, list.length));

  const paginate = (pageNumber) => {
    setcurrentpage(pageNumber);
  };

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Pages List " />
      <Box>
        <Box className="px-5">
          <ToastContainer />
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {list ? (
            list.length === 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
              >
                {/* ... (table structure) */}
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((x, i) => {
                        return <th style={{backgroundColor:"#235D97",color:"#fff"}} key={i}>{x.name}</th>;
                      })}
                  </tr>
                </thead>
                  <tbody>
                    {currentpost.map((item, key) => (
                      <tr key={key} >
                        {cols.map((x, i) => (
                          <>
                          {x.key == "condition" ? 

                          <td style={{maxWidth:"250px",verticalAlign:"middle"}} key={i}>{x.displayField ? x.displayField(item, key) :  item[x.key]} </td>
                          :
                          <td style={{maxWidth:"200px",verticalAlign:"middle"}}  key={i}>{x.displayField ? x.displayField(item, key) :  item[x.key]}</td>
                        }
                          </>
                        ))} 
                      </tr>
                    ))}
                  </tbody>
              </table>
            )
          ) : null}
        </div>
      </Box>
      <Pagination perpge={postperpge} total={list.length} paginate={paginate} />

    </div>
  );
};

export default RulesList;
