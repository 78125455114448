import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  FileOutlined,
  DashboardOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SubMainDash from '../DashboardScreen/SubMainDash';
import ListCategory from '../DashboardScreen/Category/ListCategory';
import CreateCategory from '../DashboardScreen/Category/CreateCategory';
import EditCategory from '../DashboardScreen/Category/EditCategory';
import RulesList from '../DashboardScreen/Page/RulesList';
import EditRules from '../DashboardScreen/Page/EditRule';
import CreatePlan from '../DashboardScreen/Plans/CreatePlan';
import PlansList from '../DashboardScreen/Plans/PlansList';
import EditPlans from '../DashboardScreen/Plans/EditPlans';
// import ListLevel from '../DashboardScreen/LevelList/ListLevel';
// import ListCreate from '../DashboardScreen/LevelList/ListCreate';
import UserView from '../DashboardScreen/User View/UserView';
import CreateAdmin from '../DashboardScreen/Admin/CreateAdmin';
import ListAdmin from '../DashboardScreen/Admin/ListAdmin';
import EditAdmin from '../DashboardScreen/Admin/EditAdmin';
import QuestionCreate from '../DashboardScreen/Question/QuestionCreate';
import QuestionList from '../DashboardScreen/Question/QuestionList';
import QuestionLevel from '../DashboardScreen/Question/QuestionLevel';
import QuestionCreateList from '../DashboardScreen/Question/QuestionCreateList';
import EditQuestion from '../DashboardScreen/Question/EditQuestion';
import PaymentList from '../DashboardScreen/Payment/PaymentList';
import Setting from '../DashboardScreen/GernalSettings/Setting';
import CreateSettings from '../DashboardScreen/GernalSettings/CreateSettings';
import EditSetting from '../DashboardScreen/GernalSettings/EditSetting';
import UserDetails from '../DashboardScreen/User View/UserDetails';
import UserDetails2 from '../DashboardScreen/User View/UserDetails2';

const { Sider, Content } = Layout;

const MainDashboard = () => {
  const navig = useNavigate();
  const location = useLocation();

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  let nav = [

    {
      mainitem: {
        name: "Admin"
      },
      item1: {
        name: "Admin List",
        key: "adminList"
      },
      item2: {
        name: "Create Admin",
        key: "createAdmin"
      },
    },

    {
      mainitem: {
        name: "Categories"
      },
      item1: {
        name: "List Categories",
        key: "listCategories"
      },
      item2: {
        name: "Create Categories",
        key: "createCategories"
      },
    },
    {
      mainitem: {
        name: "Page"
      },
      item1: {
        name: "Page ",
        key: "rulesList"
      }
    },
    {
      mainitem: {
        name: "Plans"
      },
      item1: {
        name: "Plan",
        key: "planList"
      },
    },
    {
      mainitem: {
        name: "Payment"
      },
      item1: {
        name: "Payment List",
        key: "paymentList"
      },

    },
    {
      mainitem: {
        name: "Question"
      },
      item1: {
        name: "Question List",
        key: "questionList"
      },
      item2: {
        name: "Create Question",
        key: "questionC_List"

      },
    },

    {
      mainitem: {
        name: "User"
      },
      item1: {
        name: "User List",
        key: "userList"
      }
    },
    {
      mainitem: {
        name: "Gernal Settings"
      },
      item1: {
        name: "Setting",
        key: "setting"
      }
    }

  ]


  const navItem = (e) => {
    navig(`${e}`);
  };

  useEffect(() => {
    // Set the selected menu item based on the current route
    const currentPath = location.pathname.slice(1);
    setSelectedKeys([currentPath]);
  }, [location.pathname]);

  return (
    <Layout style={{ minWidth: "207px", minHeight: '100vh', backgroundColor: "#1E5082" }}>
      <Sider width={250} style={{ backgroundColor: "#1E5082" }} theme="dark">
        <div className="logo" />
        <Menu
          style={{ backgroundColor: "#1E5082", color: "#fff" }}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={(keys) => setOpenKeys([keys.pop()])}
        >
          <Menu.Item
            // theme="dark"
            onClick={() => navItem('')}
            key="dashboard"
            icon={<DashboardOutlined />}
            selectedKeys={selectedKeys}
            style={{ backgroundColor: "#7d8597", color: "#fff" }}
          >
            Dashboard
          </Menu.Item>
          {nav.map((item) => (
            <Menu.SubMenu
              key={item.mainitem.name}
              icon={<FolderOpenOutlined />}
              title={item.mainitem.name}
            >
              <Menu.Item
                onClick={() => navItem(item.item1.key)}
                key={item.item1.key}
                icon={<UserOutlined />}
                selectedKeys={selectedKeys}
              >
                {item.item1.name}
              </Menu.Item>
              {item.item2 && (
                <Menu.Item
                  onClick={() => navItem(item.item2.key)}
                  key={item.item2.key}
                  icon={<FileOutlined />}
                  selectedKeys={selectedKeys}
                >
                  {item.item2.name}
                </Menu.Item>
              )}
            </Menu.SubMenu>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Routes>
          <Route path='/' element={<SubMainDash />} />

          <Route path='listCategories' element={<ListCategory />} />
          <Route path='createCategories' element={<CreateCategory />} />
          <Route path='editCategories' element={<EditCategory />} />

          {/* <Route path='editListlevel' element={<EditListLevel />} /> */}
          {/* <Route path='listLevel' element={<ListLevel />} /> */}
          {/* <Route path='createListLevel' element={<ListCreate />} /> */}

          <Route path='rulesList' element={<RulesList />} />
          {/* <Route path='createRules' element={<RulesCreate />} /> */}
          <Route path='editRules' element={<EditRules />} />

          <Route path='planlist' element={<PlansList />} />
          <Route path='createPlan' element={<CreatePlan />} />
          <Route path='editplans' element={<EditPlans />} />

          <Route path='adminList' element={<ListAdmin />} />
          <Route path='createAdmin' element={<CreateAdmin />} />
          <Route path='editAdmin' element={<EditAdmin />} />

          <Route path='createquestion' element={<QuestionCreate />} />
          <Route path='questionlevel' element={<QuestionLevel />} />
          <Route path='questionList' element={<QuestionList />} />
          <Route path='questionC_List' element={<QuestionCreateList />} />
          <Route path='editquestion' element={<EditQuestion />} />

          <Route path='paymentList' element={<PaymentList />} />

          <Route path='userList' element={<UserView />} />
          {/* <Route path='userDetail' element={<UserDetails />} /> */}
          {/* updated code  */}
          <Route path='userDetail' element={<UserDetails2 />} />

          <Route path='setting' element={< Setting />} />
          <Route path='createSetting' element={<CreateSettings />} />
          <Route path='editSetting' element={<EditSetting />} />


        </Routes>
      </Layout>
    </Layout>
  );
};

export default MainDashboard;
