import { Box } from '@mui/material'
import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-bootstrap'
import Header from '../../Comp/Header'
import MainHeader from '../../Comp/MainHeader'
import BsTable from '../../Comp/BsTable'
import { DeleteApi, GetApi } from '../../Api/BaseHandle'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import HaederButton from '../../Comp/HeaderButton'
import DButton from '../../Comp/DButton'
import DelButton from '../../Comp/DelButton'
 
const ListAdmin = () => {
  let [open, setopen] = useState(false)
  let [data, setdata] = useState([])
  let navigation = useNavigate()

  const Getdata = () => {
    GetApi("/admin/user/getAll").then((res) => {
      setdata(res.data.data)
    }).catch((e) => console.log(e))
  }
  useEffect(() => {
    Getdata()
  }, [])

  let edit = (e) => {
    navigation("/dashboard/editAdmin", {
      state: e
    }
    )
  }
  let Delete = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
         DeleteApi("/admin/user/delete",e._id).then((res)=>{
   let filter =  data.filter((x)=> x._id !== e._id)    
     setdata([...filter])                 
    }).catch((e)=>{
          toast.error('Failed', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
      }
      
    })   
  }
  return (
    <div>
      <MainHeader />
      <Header screenTitle="Admins List" />
      {/* <DButton/> */}
      <Box>
        <Box className="px-5 " >
          <ToastContainer />
        </Box>
        <Box>
        <HaederButton path="/dashboard/createAdmin"/>
        </Box>
        {open ?
          <Box sx={{ height: "55%" }} className="justify-content-center ">
            {/* <img className='w-25' src={Img} alt="Centered Image" /> */}
            <Box>
              <Typography variant='h6' className='text-dark pt-1 fs-4 px-2'>
                Data Not Found...
              </Typography>
              <Typography variant='p' className='text-dark pt-1 fs-6 px-2'>
                You have to Create First
              </Typography >
            </Box>
          </Box>
          :
          <BsTable data={data} cols={[
            {
              name: "Name",
              key: "name"
            },
            {
              name: "Email",
              key: "email"
            },            
            {
              name: "Action",
              displayFeild: (e) => (
                <>
                  {/* <Button type="primary" onClick={() => edit(e)}>edit</Button> */}
                  <DButton text="Edit" click={() => edit(e)} />
                  {/* <Button className='mx-2' type="primary" danger onClick={() => Delete(e)}>Delete</Button> */}
                  <DelButton text="Delete" click={() => Delete(e)} />
                </>
              )
            },

          ]} />
        }
      </Box>
      <BsTable />
    </div>
  )
}

export default ListAdmin
