import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainHeader from '../../Comp/MainHeader';
import Header from '../../Comp/Header';
import { ToastContainer } from 'react-bootstrap';
import './UserDetails.css'; // Ensure you have this CSS file for styling
import dayjs from 'dayjs';

const UserDetails = () => {
    let [selectedCategory, setSelectedCategory] = useState(null);
    let [categories, setCategories] = useState([]);
    let location = useLocation();
    let { state } = location;

    useEffect(() => {
        if (state && state.data) {
            console.log(state.data);
            console.log(Object.keys(state.data), "user data");
            setCategories(Object.keys(state.data)); // Set categories from data keys
            setSelectedCategory(Object.keys(state.data)[0]); // Automatically select the first category
        }
    }, [state]);

    const formatTimeInMinutes = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    };

    const renderCategoryData = (category) => {
        const data = state.data[category];
        return (
            <>
                <div className='d-flex'>
                    <div className='w-25 p-3 text-white' style={{ backgroundColor: " rgb(35, 93, 151)" }}>
                        <strong>Level</strong>
                    </div>
                    <div className='w-25 p-3 text-white' style={{ backgroundColor: " rgb(35, 93, 151)" }}>
                        <strong>Date</strong>
                    </div>
                    <div className='w-25 p-3 text-white' style={{ backgroundColor: " rgb(35, 93, 151)" }}>
                        <strong>Time</strong>
                    </div>

                    <div className='w-25 p-3 text-white' style={{ backgroundColor: " rgb(35, 93, 151)" }}>
                        <strong>Score</strong>
                    </div>
                </div>
                <div className='d-flex' >
                    <div className='w-25'>
                        {Object.entries(data.levels).map(([level, details], index) => (
                            <div className='w-100 d-flex flex-column eachcustomtable'>
                                <strong> {level}</strong>
                            </div>
                        ))}
                    </div>
                    <div className='w-25' >
                        {Object.entries(data.levels).map(([level, details], index) => (
                            <div className='w-100  d-flex flex-column eachcustomtable'>
                                {details?.date ? dayjs(details?.date).format('M/D/YYYY') : "------"}
                            </div>
                        ))}
                    </div>
                    <div className='w-25' >
                        {Object.entries(data.levels).map(([level, details], index) => (
                            <div className='w-100 d-flex flex-column eachcustomtable'>
                                {formatTimeInMinutes(details.timer)}
                            </div>
                        ))}
                    </div>
                    <div className='w-25' >
                        {Object.entries(data.levels).map(([level, details], index) => (
                            <div className='w-100  d-flex flex-column eachcustomtable'>
                                {`${details.correctAnswers}/${details.totalQuestions}`}
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <MainHeader />
            <Header screenTitle="Quiz Details" />
            <ToastContainer />
            <div className='container mt-2'>
                <h2 className='text-center py-2 heading'>{state.name || "Quiz Details"}</h2>
                <div className="category-list">
                    {categories?.map((category, index) => (
                        <button
                            key={index}
                            onClick={() => setSelectedCategory(category)}
                            className={`category-button ${selectedCategory === category ? 'active' : ''}`}>
                            {category}
                        </button>
                    ))}
                </div>
                <div className="category-data mt-3">
                    {selectedCategory && renderCategoryData(selectedCategory)}
                </div>
            </div>
        </>
    );
}

export default UserDetails;
