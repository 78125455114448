

import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react'
import DButton from './DButton'
import Modal from '@mui/material/Modal';
import { Input, Select } from 'antd';
import { Button } from '@mui/material';
import { PostApi } from '../Api/BaseHandle';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';

const style = {
  position: 'absolute',
  bottom: '30%',
  left: '50%',
  transform: 'translate(-50%, -0%)',
  width: 420,
  height: 320,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 6,
};

export default function ModelImport(props) {
  const [status, setstatus] = useState("yes");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  let { open, closed, data } = props;

  const handleClose = () => {
    closed(false)
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file ? file.name : "");
  };

  const Export = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("name", data?.name);
      formData.append("level", "1");
      formData.append("img", data?.img);
      // formData.append("", d);
      formData.append("file", selectedFile);
      PostApi(`/admin//categorylist/save/${data?.nestedItemId}/items/csv`, formData).then((res) => {
        toast.success('File data add Successfuly', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }).catch((e) => {
        toast.error('Some error please check your file', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      })
    }


    closed(false);
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <ToastContainer/>  
          <h3 className='text-center mb-2' variant="h6">
            {data?.name}
          </h3>
          <Input disabled={true} value={data?.name} style={{ height: "34px" }} placeholder='Search Bar' type="search" />
          <img className='mt-2 table_img' width={"20%"} height={"29%"} src={data?.img} alt="Preview" />
          <br />
          <label htmlFor="fileInput" className="my-3">
            <input
              type="file"
              accept=".csv"
              id="fileInput"
              className="form-control"
              style={{ width: "15vh", display: "none" }}
              onChange={handleFileChange} // Added the onChange event
            />
            {/* <Button
            variant="contained"
            component="span"
            style={{ backgroundColor: "#1e5082", color: "#fff" }}
          >
            Choose File
          </Button> */}
            <DButton compenet="span" text="Choose File" />
            <span className='m-0 p-0 ms-1' style={{ fontSize: "12px" }}>{selectedFileName}</span>
          </label>
          <br />
          <div className='text-center my-2'>
            <DButton click={Export} text="Submit" />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
