import React from 'react';
import { RiImageEditLine } from "react-icons/ri";
const UploadImage = (props) =>{
    let {onUpload } = props

    return(
        <div className="form-group row">
        <label htmlFor="icon" className="cursor-pointer">
         Select image <RiImageEditLine size={20} />
        </label>
        <input
            onChange={onUpload}
            type="file"
            id="icon"
            name="icon"
            style={{ display: "none" }}
        />
    </div>

    )
} 
export default UploadImage;