import { Box } from '@mui/material';
import { Typography } from 'antd';
import React, { useState } from 'react';
import Header from '../../Comp/Header';
import MainHeader from '../../Comp/MainHeader';
import BsButton from '../../Comp/BsButton';
import { PostApi } from '../../Api/BaseHandle';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreatePlan = () => {
  const [model, setModel] = useState({
    name: '',
  });
  let [disable, setdisplay] = useState(false)
  const notify = (message) => toast.error(message);// State to manage validation errors
  const navigation = useNavigate();

  const sendData = () => {
    // Validate the form before sending data
    if (!model.name) {
      notify('Name is required');
      return;
    }
    if (!model.price) {
      notify('Price is required');
      return;
    }
    setdisplay(true)

    // If validation is successful, proceed to send data
    PostApi('admin/pro/get', model)
      .then((res) => {
        toast.error('Data submitted successfully.');
        navigation('/dashboard/planlist');
        setdisplay(false)
      })
      .catch((e) => {
        toast.error('Error submitting data.');
      });
  };

  return (
    <div>
      <MainHeader />
      <Header screenTitle="Create Plan" />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className="shadow m-auto mt-2">
        <Box>
          <ToastContainer />
          <Box className="row p-3">
            <div className="col-md-6">
              <Typography className="" variant="p">
                Name :
              </Typography>
              <div className="mt-2">
                <input
                  className="form-control w-100"
                  value={model.name || ''}
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setModel({ ...model, name: e.target.value })}
                />
              </div>
            </div>

            <div className="col-md-6">
              <Typography className="" variant="p">
                Price :
              </Typography>
              <div className="mt-2">
                <input
                  className="form-control w-100"
                  value={model.price || ''}
                  type="number"
                  name=""
                  id=""
                  onChange={(e) => setModel({ ...model, price: e.target.value })}
                />
              </div>
            </div>

            <Box sx={{ display: 'flex', justifyContent: 'start' }} className="py-3">
              <BsButton isButtonDisabled={disable} text="submit" onClick={sendData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreatePlan;
