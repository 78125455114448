import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainHeader from '../../Comp/MainHeader';
import Header from '../../Comp/Header';
import { Button, Skeleton } from 'antd';
import { DeleteApi, GetApi, PostApi } from '../../Api/BaseHandle';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import Pagination from '../../Comp/Pagination';
import DButton from '../../Comp/DButton';
import DelButton from '../../Comp/DelButton';

const QuestionLevel = () => {
  const [arr, setArr] = useState([]);
  const [id, setid] = useState();
  const loca = useLocation();
  const navigate = useNavigate();
  const { state } = loca;
  const [currentpage, setcurrentpage] = useState(1);
  const [postperpge, setpostperpge] = useState(20);
  const [loading, setLoading] = useState(true);
  const [not, setnot] = useState(true);

  let GetData = () => {
    PostApi("/admin/categoryListAll/admin/name/find", { name: state.name })
      .then((res) => {
        // if (res?.data?.data?.length > 0) {
        //   const mergedNestedItems = res.data.data.flatMap((item) =>
        //     item.nestedItem.map((nestedItem) => ({
        //       ...nestedItem,
        //       parentId: item._id,
        //     }))
        //   );
        //   setArr(mergedNestedItems);
        // }



        if (res?.data?.data?.length > 0) {
          const mergedNestedItems = res.data.data[0].nestedItem.flatMap((item) =>
          ({
            ...item,
            parentId: res?.data?.data[0]._id
          })
          );
          setArr(mergedNestedItems);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetData();
    setid(state.id);
  }, []);

  if (loading) {
    return <>
      <MainHeader />
      <Header screenTitle="Question List" />
      <div className='container'>


        <div className="quiz-question " >
        <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />

          <div className="options">
            <h5 className='mt-3'>Options :</h5>
            <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />
            <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />
            <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />
            <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />
            <h5 className='mt-3'>Correct Answer :</h5>
            <Skeleton.Input style={{ width: '90vh', marginBottom: "5px" }} active animation="wave" />
            <h5 className='mt-3'>Explanation :</h5>
            <Skeleton.Input style={{ width: '5vh', marginBottom: "5px" }} active animation="wave" />
            <br/>
          </div>
          <Skeleton.Input style={{ width: '25px', }} active animation="wave" />
          <Skeleton.Input style={{ width: '25px', marginLeft: "20px" }} active animation="wave" />
        </div>
      </div>
    </>
  }

  let handleDelete = (e) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the action
        axios.delete(`https://backend.cloudexamspro.com/api/admin/categorylist/save/${e.parentId}/items/${e._id}/delete`).then((res) => {
          let filData = arr.filter((x) => x._id !== e._id);
          setArr([...filData]);
        }).catch((e) => console.log(e));
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }
    });
  };

  let handelEdit = (e) => {
    console.log(e)

    navigate("/dashboard/editquestion", {
      state: {
        data: e,
        id_L: e.parentId,
        id: e._id,
      }
    });
  }

  const indexoflastpost = currentpage * postperpge;
  const indexofFirstpost = indexoflastpost - postperpge;
  const currentpost = arr?.slice(indexofFirstpost, indexoflastpost);
  const paginate = (pageNumber) => setcurrentpage(pageNumber);

  
  return (
    <>
      <MainHeader />
      <Header screenTitle="Question List" />
      <ToastContainer />
      {currentpost.length > 0 ? (
        <div className='container mt-2'>
          {currentpost.map((x, i) => (
            <div className="quiz-question" key={i}>
              <h3>{x.question}</h3>
              <div className="options">
                {x.answer && x.answer.length > 0 && (
                  <>
                    <h5 className='mt-3'>Options :</h5>
                    {x.answer.map((option, index) => (
                      option !== "" && option !== null && (  // Check for non-null and non-empty strings
                        <div key={index} className="option">
                          {option}
                        </div>
                      )
                    ))}
                  </>
                )}
                {x.correctAnswer && x.correctAnswer.length > 0 && (
                  <>
                    <h5 className='mt-3'>Correct Answer :</h5>
                    <div className="option">{x.correctAnswer.join(', ')}</div>
                  </>
                )}
                {x.explanation && x.explanation !== "" && (
                  <>
                    <h5 className='mt-3'>Explanation :</h5>
                    <p>{x.explanation}</p>
                  </>
                )}
              </div>
              <DButton click={() => handelEdit(x)} />
              <DelButton click={() => handleDelete(x)} />
            </div>
          ))}
          <Pagination perpge={postperpge} total={arr.length} paginate={paginate} />
        </div>
      ) : (
        <h2 className='text-center mt-5'>Questions Not Available</h2>
      )}
    </>
  );
};

export default QuestionLevel;