import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import MainHeader from '../../Comp/MainHeader';
import Header from '../../Comp/Header';
import { Box, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { Select, Typography } from 'antd';
import BsButton from '../../Comp/BsButton';
import { PostApi } from '../../Api/BaseHandle';
import UploadImage from '../../Comp/UploadImage';
import convertToBase64 from '../../Comp/Converter';
import DelButton from '../../Comp/DelButton';

const EditSetting = () => {

    const [model, setModel] = useState({});
    let [disable, setdisplay] = useState(false)
    let [url, setUrl] = useState(false)
    let [icon, setIcon] = useState(false)
    let [text, settext] = useState()
    const notify = (message) => toast.error(message);// State to manage validation errors
    const navigate = useNavigate();
    const loaction = useLocation();
    let { state } = loaction

    let sendData = () => {

        if (!model.generalapp) {
            notify('Gernal Name is required');
            return;
          }
      
          if (!model.editProf) {
            notify('Edit Profile is required');
            return;
          }
      
          if (!model.purchased) {
            notify('Parchased is required');
            return;
      
          }
        
          if (!model.about) {
            notify('About is required');
            return;
          }
      
          if (!model.privacyPolicy) {
            notify('Privacy Policy is required');
            return;
          }
    
          if (!model.help) {
            notify('help is required');
            return;
      
          }
        
          if (!model.logout) {
            notify('Logout is required');
            return;
          }
      
          if (!model.message) {
            notify('Message is required');
            return;
          }
      
    
          if (text === "") {
            notify('Greeting Message is required');
            return;
          }
    
          if (!icon) {
            notify('Image is required');
            return;
        }

        const formData = new FormData();
        formData.append("generalapp", model.generalapp);
        formData.append("editProf", model.editProf);
        formData.append("purchased", model.purchased);
        formData.append("about", model.about);
        formData.append("privacyPolicy", model.privacyPolicy);
        formData.append("help", model.help);
        formData.append("logout", model.logout);
        formData.append("greetingMessage", model.greetingMessage);
        formData.append("message", model.message);
        formData.append("logo", icon);


        PostApi("/admin/setting/save", formData).then((res) => {
            navigate("/dashboard/setting")
        }).catch((e) => console.log(e))
    }

    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setUrl(base64);
        setIcon(e.target.files[0]);
        setModel({...model,logo:e.target.files[0]})
    };

    const checker = (e) => {
        settext(e);
        if (e) {
            setModel({
                ...model,
                greetingMessage: true,
            });
        } else {
            setModel({
                ...model,
                greetingMessage: false,
            });
        }
    }

    useEffect(() => {
        setModel(state)
        settext(state.greetingMessage)
        setUrl(state.logo)
        setIcon(state.logo)
    }, [])
    return (<>
        <MainHeader />
        <Header screenTitle="Edit Setting" buttonList={[
        {
          displayField: () => (
            // <Button color='error' onClick={() => navigate("/dashboard/setting")} variant='contained'>   Cancel </Button>
            <DelButton click={() => navigate("/dashboard/setting")} text="Cancel" />

          )
        }
      ]}/>
        <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className="shadow m-auto mt-2">
            <Box>
                <ToastContainer />
                <Box className="row p-3">

                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Message:
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.message || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, message: e.target.value })}
                            />
                        </div>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className="py-3">
                        <BsButton isButtonDisabled={disable} text="submit" onClick={sendData} />
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
    )
}

export default EditSetting
