import React from 'react'
import Header from '../../Comp/Header'
import { Box, Typography } from '@mui/material'
import MainHeader from '../../Comp/MainHeader'
import BsButton from '../../Comp/BsButton'
import { ToastContainer, toast } from 'react-toastify'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import { PostApi } from '../../Api/BaseHandle'
import convertToBase64 from '../../Comp/Converter'
import UploadImage from '../../Comp/UploadImage'
import { IoImagesSharp } from 'react-icons/io5'


const CreateSettings = () => {

    const [model, setModel] = useState({});
    let [disable, setdisplay] = useState(false)
    let [text, settext] = useState("")
    const notify = (message) => toast.error(message);// State to manage validation errors
    const navigate = useNavigate();
    const [icon, setIcon] = useState();
    const [url, setUrl] = useState();


    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setUrl(base64);
        setIcon(e.target.files[0]);
    };



    let sendData = () => {

        if (!model.generalapp) {
            notify('Gernal Name is required');
            return;
        }

        if (!model.editProf) {
            notify('Edit Profile is required');
            return;
        }

        if (!model.purchased) {
            notify('Parchased is required');
            return;

        }

        if (!model.about) {
            notify('About is required');
            return;
        }

        if (!model.privacyPolicy) {
            notify('Privacy Policy is required');
            return;
        }

        if (!model.help) {
            notify('help is required');
            return;

        }

        if (!model.logout) {
            notify('Logout is required');
            return;
        }

        if (!model.message) {
            notify('Message is required');
            return;
        }


        if (text === "") {
            notify('Greeting Message is required');
            return;
        }


        // const SettingSchema = mongoose.Schema({
        //     generalapp: {
        //       type: String,
        //     },
        //     editProf:{
        //       type: String,
        //     },
        //     purchased:{
        //         type: String,
        //       },
        //     about:{
        //           type: String,
        //     },
        //     privacyPolicy:{
        //           type: String,
        //     },
        //     help:{
        //           type: String,
        //     },
        //     logout: {
        //       type: String,
        //     },
        //     logo: {
        //         type: String,
        //       },
        //    greetingMessage:{
        //           type: Boolean,
        //     },
        //    message:{
        //       type: String,
        //     },

        //   });    
        const formData = new FormData();
        formData.append("generalapp", model.generalapp);
        formData.append("editProf", model.editProf);
        formData.append("purchased", model.purchased);
        formData.append("about", model.about);
        formData.append("privacyPolicy", model.privacyPolicy);
        formData.append("help", model.help);
        formData.append("logout", model.logout);
        formData.append("greetingMessage", model.greetingMessage);
        formData.append("message", model.message);
        formData.append("logo", icon);

        // return;
        PostApi("/admin/setting/save", formData).then((res) => {
            navigate("/dashboard/setting")
        }).catch((e) => console.log(e))

    }





    const checker = (e) => {
        settext(e);
        if (e) {
            setModel({
                ...model,
                greetingMessage: true,
            });
        } else {
            setModel({
                ...model,
                greetingMessage: false,
            });
        }
    }


    return (<>
        <MainHeader />
        <Header screenTitle="Create Setting" />
        <ToastContainer />
        <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className="shadow m-auto mt-2">
            <Box>
                <Box className="row p-3">
                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Gernal App :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.generalapp || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, generalapp: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Edit Profile :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.editProf || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, editProf: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Purchased :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.purchased || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, purchased: e.target.value })}
                            />
                        </div>
                    </div>


                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            About Us :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.about || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, about: e.target.value })}
                            />
                        </div>
                    </div>


                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Privacy Policy :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.privacyPolicy || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, privacyPolicy: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                        Terms & Conditions :
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.help || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, help: e.target.value })}
                            />
                        </div>
                    </div>



                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Logout:
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.logout || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, logout: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 my-2">
                        <Typography className="" variant="p">
                            Message:
                        </Typography>
                        <div className="mt-2">
                            <input
                                className="form-control w-100"
                                value={model.message || ''}
                                type="text"
                                name=""
                                id=""
                                onChange={(e) => setModel({ ...model, message: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className='col-md-6 my-2'>
                        <Typography className='mx-2 mt-2' variant='p'>
                            Greeting Message :
                        </Typography>
                        <div className='mt-2'>
                            <Select className='w-100' placeholder='Select an option' value={text} onChange={checker}>
                                <Select.Option value={true}>True</Select.Option>
                                <Select.Option value={false}>False</Select.Option>
                            </Select>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2">
                        <UploadImage onUpload={onUpload} />
                        {url && url.slice(0, 7) === "uploads" ? (
                            <img
                                src={`${url}`}
                                alt="Icon"
                                style={{ width: "100px", height: "50px" }}
                            />
                        ) :
                            (
                                url ? <img src={url} alt="Icon" style={{ width: "80px" }} />
                                    : <IoImagesSharp size={50} />)}
                    </div>

                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className="py-3">
                        <BsButton isButtonDisabled={disable} text="submit" onClick={sendData} />
                    </Box>
                </Box>
            </Box>
        </Box>
    </>)
}

export default CreateSettings
